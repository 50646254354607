import decode from '../utils/decode'
import { ARTICLE, DAY, MONTH, YEAR } from '../constants'
import {
  buildArticlePermalink,
  buildDayPermalink,
  buildMonthPermalink,
  buildYearPermalink,
} from '../utils/urls'

const DEFAULT_BUILDER = (node) => `/${node.uri}`

const BUILDERS = {
  [ARTICLE]: buildArticlePermalink,
  [DAY]: buildDayPermalink,
  [MONTH]: buildMonthPermalink,
  [YEAR]: buildYearPermalink,
}

export function mapNodeToEntry(node, hrefBuilder = DEFAULT_BUILDER) {
  const { id, title, date, featuredImage } = node || {}

  let picture = null
  if (featuredImage && featuredImage.mediaType === 'image') {
    const { mediaDetails, mediaItemUrl, altText } = featuredImage

    picture = {
      ...mediaDetails,
      alt: decode(altText || ''),
      src: mediaItemUrl,
    }
  }

  return {
    id,
    title: decode(title || ''),
    href: hrefBuilder(
      parseNodeData(node),
    ),
    date,
    picture,
  }
}

export function buildMapper(type) {
  const hrefBuilder = BUILDERS[type]

  return (node) => mapNodeToEntry(node, hrefBuilder)
}

export function parseNodeData(data) {
  const { date = {}, slug, uri, id } = data
  const { year, month, day } = date

  return {
    id,
    uri,
    slug,
    year,
    month,
    day,
  }
}

export const mapNodeToArticle = buildMapper(ARTICLE)
