// eslint-disable-next-line import/no-unresolved
import he from 'he?server'

let decode

if (import.meta.env.SSR) {
  decode = he.decode
} else {
  decode = (html) => {
    const decoder = document.createElement('div')

    // Escape HTML before decoding for HTML Entities
    html = escape(html)
      .replace(/%26/g, '&')
      .replace(/%23/g, '#')
      .replace(/%3B/g, ';')

    // decoding
    decoder.innerHTML = html

    return unescape(decoder.textContent)
  }
}

export default decode
